.modal-dialog{
    max-width:80%;

}

thead tr{
    text-align: center !important;
}

.font-header{
    font-family: var(--text-font);
    color: var(--PlainShadow);
    font-size: 18px;
    font-weight: bold;
    margin: 0px 30px;
    padding: 8px 0px;
}
.border-color{
    border: var(--borderColor);
    border-radius: 5px;
border-bottom:0xp !important;
}
.border-listing{
    border-top: 0px !important;
    border:var(--borderColor);
}
.margn-right{
    margin-right: 40px;
}

.apply-coupon{
    border: 1px solid var(--darkBlue);
    background: white;
    padding: 10px 20px;
    margin: 10px;
    color: var(--darkBlue);
    font-size: 16px;
    font-weight: bold;
    font-family: var(--text-font);

}

.coupon_input{
   border: var(--borderColor);
    outline: 0;
    background:gainsboro;
    padding: 10px 5px;
    margin: 10px;

}