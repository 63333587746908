@font-face {
  font-family: "Edo";
  src: url("assets/fonts/edo.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Karla", Helvetica, Arial, Lucida, sans-serif;
  src: url("assets/fonts/Karla-VariableFont_wght.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

:root {
  --main-font: "Edo", sans-serif;
  --text-font: "Karla", Helvetica, Arial, Lucida, sans-serif;
  --plainColor:#595959;
  --PlainShadow:rgba(149, 157, 165, 0.2) 0px 8px 24px;
  --camelColor:#C6A86F;
  --borderColor:1px solid #e5e5e5;
  --maroon:#AD1F00;
  --darkBlue:#1095c1;
  --darkTheme:#212529;
 
}

.dark-theme{
  background:#212529;
  color:white !important;
}

.cursor-pointer{
  cursor: pointer;
}
h1, h2, h3, h4 {
  font-family: var(--main-font);
}
.Img004{
  width: 100%;
}

.checkout-sec{
  padding-top: 50px;

}
.checkout-container{
  font-family: 'Roboto', Helvetica, Arial, Lucida, sans-serif;
  padding:1%;
  box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.3);
  padding: 20px 0px;
}

.checkout-sec input{
  width: -webkit-fill-available;
    padding: 15px;
    font-size: 14px;
    border-radius: 3px;
    background-color: #fff;
    border: 1px solid #bbb;
    
    color: #4e4e4e;

}

.checkout-sec label{
  font-size: 16;
  font-family:var(--text-font);
  font-weight: 800;
}

.table-sec tr   {


  border-width: 1px;
  border-block-color: #aeaeae !important;
  padding: 10px, 0px ;
  border-radius: 10px;

} 
.table-sec table, tr{
  width: 100%;
}

.table-sec th{
  padding: 11px 10px;
}
.heckout-sec h1, h2, h3, h4{
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}
.Click-here-Row{
  padding: 0px 10px;
}
.Click-here-Row .col-md-12{
  padding: 20px 10px 8px 10px;
  background-color: #2ea3f2;
}
.checkout-sec h5{
  color: #fff;
  font-size: 18px;
}

.checkout-sec  a {
  text-decoration: auto;
    color: #fff;
}
.Click-here-Row1{
  padding: 0px 10px;
}
.Click-here-Row1 .col-md-12{
  padding: 20px 10px 8px 10px;
  background-color: #9d7c3d!important;
}
.checkout-sec button{
  font-size: 20px;
  font-weight: 500;
  padding: 8px 30px;
  line-height: 1.7em !important;
  background: transparent;
  position: relative;
  border: 2px solid;
  border-radius: 3px;
  border-color: #2ea3f2;
  color: #2ea3f2;
  
  transition: all .2s;
}
.myaccount h2{
  color: #2ea3f2;
}
.checkout-sec .button:hover {
  background-color: #e7e7e7;

 
}
.Dropdown-sec{
  border: 1px solid #cfc8d8;
  padding: 20px;
  margin: 1em 0;
  text-align: left;
  border-radius: 5px;
}

.review {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 5px;
  width: 70%;
  text-align: center;
}
.carousel-item {
  padding: 20px;
}
