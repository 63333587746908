.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  height: 684px !important;
  width: 100%;
}

.swiper-slide-active {
  height: 684px;
  width: 100%;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.bg-white {
  background: #fafafa;
}
.scroll-bar ::-webkit-scrollbar {
  display: none !important;
}

.swiper-height {
  height: 200px !important;
}
.swiper-height :active {
  height: auto !important;
}

.box-shadow {
  margin: 0px 5px;
  background: #fafafa;
}

@media (max-width: 750px) {
  .swiper-slide {
    height: 200px !important;
    width: 100%;
  }
  .swiper-slide-active {
    height: 200px !important;
    width: 100%;
  }
  .swiper-height{
    margin: 10px 5px;
  }
}
