.heading_text {
  color: #333;
  padding-bottom: 20px;
  line-height: 1em;
  font-weight: 500;
  font-family: var(--main-font) !important;
  border-bottom: var(--borderColor);
}
.cart_img_fixed {
  position: absolute;
  top: 0px;
}

.text-plain {
  font-size: 16px;
  font-family: var(--text-font);
  border-bottom: var(--borderColor);
  padding-bottom: 20px;
}

.text-plain-bold {
  font-size: 16px;
  font-family: var(--text-font);
  padding-bottom: 20px;
  font-weight: bold;
}

.attributes {
  border: var(--borderColor);
  padding: 8px 25px;
  background-size: 0 100%;
  cursor: pointer;
}

.attributes:hover {
  background: linear-gradient(#1095c1 0 0) no-repeat;
  transition: 0.4s, background-position 0s;
  color: white !important;
  background-size: 100% 100%;
}

.add_basket {
  background: #ded3bf;
  border-radius: 10px;
  color: white;
  padding: 10px 10px;
  border: 0px;
}
.input_container {
  width: 100px;
  background: #cccccc;
  border-radius: 5px;
  color: white;
  padding: 5px 10px;
  border: 0px;
}
.input_container input {
  outline: 0;
  border: none;
  background: transparent;
  width: 100%;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
}

.input_container input::-webkit-input-placeholder {
  color: white !important;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
}
.display-none {
  height: 0px;
  position: relative;
  visibility: hidden;
  transform: translateY(-100%);
  opacity: 0;
  transition: transform 0.8s ease-out, opacity 0.8s ease-out;
}

.display-add-show {
  height: auto;
  visibility: visible;
  transform: translateY(0);
  opacity: 1;
  position: relative;
  background: white;
}

.clear-text {
  position: absolute;
  top: 0px;
  right: 60%;
  cursor: pointer;
}

.infotab-header {
  background-color: #f4f4f4;
  border: var(--borderColor);
}

.infotab-header-current {
  background: white;
}
.tab {
  background: white;
  border: var(--borderColor);
  padding: 0px 20px;
  height: 420px;
  position: relative;
}

.tab p {
  font-family: var(--text-font);
  font-weight: 400;
}

.active_button {
  background: white;

  border: var(--borderColor);
}

.inactive {
  cursor: pointer;
}

.font-weight-bold {
  font-weight: bold;
}

.cursor-pointer {
  cursor: pointer;
}

.review_input {
  width: 100%;
  background-color: #eeeeee;
  height: 100px;
  margin: 20px 0px;
}

.review_input input {
  border: 0px;
  background: transparent;
  height: 100%;
  outline: none;
  width: 100%;
  padding: 0px 20px;
}

.review-submit {
  background: transparent;
  border: 2px solid #1095c1;
  padding: 5px 30px;
  color: #1095c1;
  position: absolute;
  right: 20px;
  font-size: 20px;
}

.review-submit:hover {
  background: #eeeeee;
  transition: 0.5s;
  border: 2px solid #eeeeee;
}
.tooltip-show{
  background: #1095c1;
  border-radius:5px;
  padding: 5px;
  height: 30px;
  border-radius: 15px 0px 15px 0px;
  position: absolute;
  right: 50%;
  top: -30px;
  visibility: hidden;

}


.show-in {
  visibility: visible;
 
}

.view-cart{
  background: #547da9 !important;
  color: white !important;
  border: 0px;
  padding: 5px 15px;
  border-radius: 5px;

}
.bg-brown{
  background: #BDA87F;
  color: white;
  padding: 10px 50px;
  margin:20px ;
}


.border-blue{
  border: 1px solid var(--darkBlue);
}