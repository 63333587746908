.card_title_name {
  font-size: 26px;
  font-family: var(--main-font);
  color: #666666;
}

.contact-title-font {
  font-size: 18px;
  font-family: var(--main-font);
  color: #666666;
}
.contact-content-section {
  font-size: 14px;
  font-family: var(--text-font);
  color: #666666;
}

.essence_heading {
  font-family: var(--main-font);
}

.product-card-title {
  font-size: 16px;
  font-family: var(--text-font);
  font-weight: bold;
  text-align: center;
  margin: 10px 0px;
}
.price-text-style {
  font-size: 16px;
  font-family: var(--text-font);
  font-weight: bold;
  text-align: center;
  margin: 10px 0px;
  color: var(--camelColor);
}
.border_radius_profile {
  width: 50px;
  height: 45px;
  margin: 0px 10px;

}
.border_radius_profile img{
  border-radius: 50px !important;
}

.google_review_button{
  background:#0A6CFF !important;
  color: white;
  padding: 5px 10px;
  border-radius: 20px;
  border: 0px;
  display: flex;
  margin-bottom: 0px;
  margin-top: 5px;

}


@media (max-width: 750px) {


  
}

