.nav-link {
  font-size: 15px;
  font-weight: 700;
  font-family: var(--text-font);
}

.cart-account {
  margin-right: 40px;
}
.cart-account a {
  color: var(--plainColor);
  margin: 0px 2px;
  padding: 0px 10px;
  border-radius: 10px;
}

.cart-account a:hover {
  opacity: 0.7;
  transition: all 0.6s ease-in-out;
}

.cart-account a span {
  margin: 0px 5px;
}
.nav-link-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.DropdownContainer {
  position: absolute;
  top: 90px;
  width: 15%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  z-index: 999;
  opacity: 0;
  background: white;
  margin-left: -33px;
  transition: opacity 0.3s ease-in-out;
  visibility: hidden;
  font-size: 15px !important;
  

}
.nav-link:hover .DropdownContainer {
  opacity: 1;
  visibility: visible;
 }

.cartClass{
  padding: 8px 40px;

}

.cartClass:hover{
  background: var(--darkBlue);
  color: white;
}

.searchBarClass{
outline: 0;
border: 0px;
width: 100%;
}

.offcanvas-top{
  height: 60px !important;
}

@media (max-width: 750px) {
.cart-account{
  justify-content: space-between;
  width: 100%;
}
.app-logo{
  height: 60px;
  width: 150px;
}
.navbar-toggler{
  margin-left:10px !important;
  border: 0px solid !important;
}
.navbar-nav{
  width: 100%;
}
.bi-chevron-down{
  display: none !important;
}
.DropdownContainer{
  opacity: 1;
  width: 100%;
  position: unset;
  box-shadow: none;
}
}