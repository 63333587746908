@media only screen and (max-width: 600px) {
    .footer{
        height: 100%;
        padding: 30px 0px 30px 0px;
      }
  }
  @media only screen and (min-width: 601px) {
    .footer{
        height: 251px;
        padding: 30px 0px 30px 0px;
      }
  }

.footer h4{
    font-family: 'Edo', sans-serif;
    padding: 0px 0px 5px 0px;
    color: #666;
    font-size: 20px;
}
.footer hr {
    margin: 8px 0;
    color: inherit;
    border: 0;
    border-top: var(--bs-border-width) solid;
    opacity: .25;
}

.footer a {
    color: #333;
    text-decoration: auto;
}
.amimage{
    padding: 20px 0px 32px 0px; 
    width: 182px;
    text-align: left;
    margin-left: 0;
}
.footer .logop{
    width: 300px;
}

.bottomBar{
    background-color: #efefef;
}